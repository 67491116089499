<template>
  <card-tooltip
    :options="options"
    :isLoading="isLoading"
    @refreshData="refreshData"
    :helper="helper"
  >
    <template v-slot:default>
      <div v-if="download" style="display: flow-root;" >
          <b-button v-on:click="onBtExport()" style="float:right; margin: 0px 0px 15px 0px; border-color: #ff7f50 !important; background-color: #FF7F50 !important;">Descargar</b-button>
      </div>
      <slot name="actions"></slot>
      <ag-grid-vue
        :context="context"
        style="width: 100%; height: 500px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :alwaysShowHorizontalScroll="true"
        :rowData="rowData"
        :getRowNodeId="getRowNodeId"
        :enableCellChangeFlash="enableCellChangeFlash"  
        :defaultColDef="defaultColDef"
        :rowModelType="rowModelType"
        :serverSideStoreType="serverSideStoreType"
        :cacheBlockSize="cacheBlockSize"
        :maxBlocksInCache="maxBlocksInCache"
        :autoGroupColumnDef="autoGroupColumnDef"
        :rowGroupPanelShow="rowGroupPanelShow"
        :rowSelection="rowSelection"
        :pinnedTopRowData="pinnedTopRowData"
        :rowClassRules="rowClassRules"
        :statusBar="statusBar"
        :suppressAggFuncInHeader="false"
        :animateRows="true"
        :enableRangeSelection="false"
        :pagination="true"
        :sideBar="sideBar"
        :debug="debug"
        :suppressLoadingOverlay="suppressLoadingOverlay"
        :suppressNoRowsOverlay="suppressNoRowsOverlay"
        :paginationPageSize="paginationPageSize"
        @grid-ready="onGridReady"
        @filter-changed="onFilterChanged"
        @cellValueChanged="onCellValueChanged"
        @selection-changed="onSelectionChanged"
        @first-data-rendered="onFirstDataRendered"
      ></ag-grid-vue>
      <slot name="footer"></slot>
    </template>
  </card-tooltip>
</template>


<script>

import axios from '@axios'

import
  {
    BButton, BSidebar, VBToggle, BCardText,
  } from 'bootstrap-vue'

import { AgGridVue } from "ag-grid-vue";

import CardTooltip from "@/views/brunacomponents/wrappers/CardTooltipWrapper.vue";


import ActionsCellRenderer from "./cell-renderers/ActionsCellRender.vue";
import ActionsCellRenderer2 from "./cell-renderers/ActionsCellRender2.vue";
import ActionsCellRenderer3 from "./cell-renderers/ActionsCellRender3.vue";

import CheckBoxCellRender from "./cell-renderers/CheckBoxCellRender.vue";
import StatusCellRender from "./cell-renderers/StatusCellRender.vue";

 

import NumberCellEditor from "./cell-editors/NumberCellEditor.vue";
import CheckBoxCellEditor from "./cell-editors/CheckBoxCellEditor.vue";
 

export default {
  components: {
    CardTooltip,

    BButton, BSidebar, VBToggle, BCardText,

    AgGridVue,
    customActionsCellRender: ActionsCellRenderer,
    customActionsCellRender2: ActionsCellRenderer2,
    customActionsCellRender3: ActionsCellRenderer3,

 
    checkBoxCellRender: CheckBoxCellRender,
    statusCellRender: StatusCellRender,

    numberCellEditor: NumberCellEditor,
    checkBoxCellEditor: CheckBoxCellEditor,
  },

  directives: {
  },

  props: {
    configUrl: {
      type: String,
      required: true,
    },
    dataUrl: {
      type: String,
      required: false,
    },
    editUrl: {
      type: String,
      required: false,
    },

    rowModelType: {
      type: String,
      required: false,
      default: () => "serverSide"
    },

    serverSideStoreType: {
      type: String,
      required: false,
      default: () => "partial"

    },

    actions: {
      type: Array,
      default: () => []

    },

    actionsCellRenderer: {
      type: String,
      default: () => "customActionsCellRender3"
    },

    debug: {
      type: Boolean,
      default: () => false
    },

    enableCellChangeFlash: {
      type: Boolean,
      default: () => false
    },

    suppressLoadingOverlay: {
      type: Boolean,
      default: () => true
    },

    suppressNoRowsOverlay: {
      type: Boolean,
      default: () => true

    },

    rowSelection: {
      type: String,
      default: () => null
    },

    onFirstDataRendered: {
      type: Function,
      default: (params) => { }
    },

    getRowNodeId: {
      type: Function,
      default: null

    },

    sideBar: {
      type: Object,
      default: null
    },    
  
    rowClassRules: {
      type: Object,
      default: (params) => { }
    },

    pinnedTopRowData: {
      type: Array,
      default: (params) => []


    },
        
    download: {
      type: Boolean,
      default: () => false
    },
    helper: {
      type: Boolean,
      default: () => false
    },

    cacheBlockSize: {
      type: Number,
      default: () => 100
    },

    maxBlocksInCache: {
      type: Number,
      default: () => 200
    },

    paginationPageSize: {
      type: Number,
      default: () => 100
    },




  },

  beforeMount()
  {
    this.context = {
      componentParent: this
    }
  },

  data: function ()
  {
    return {
      context: {},
      isLoading: true,



      options: {
        title: "",
        subTitle: "",
        tooltipTitle: "",
        tooltipContent: "",
        sidebarContent: []
      },

      columnDefs: [], 
      rowData: [],
      gridApi: null,
      columnApi: null,

      defaultColDef: {
        //editable: true,
        //enableRowGroup: true,
        //enablePivot: true,
        //enableValue: true,
        //sortable: true,
        headerValueGetter: (header) => { return this.$t(header.column.userProvidedColDef.headerName) },
        resizable: true,
        floatingFilter: true,
        enableCellChangeFlash: true,
        //filter: true,
        //flex: 1,
        //minWidth: 100
      },

      //paginationPageSize: 200,

      columnTypes: null,
      //cacheBlockSize: null,
      //maxBlocksInCache: null,

      autoGroupColumnDef: null,
      rowGroupPanelShow: null,
      //rowSelection: null,
      statusBar: null,
      //sideBar: null,


    };
  },
  created()
  {
    this.columnTypes = {
      number: { filter: "agNumberColumnFilter" },
    };


    //this.cacheBlockSize = 20;
    //this.maxBlocksInCache = 10;
    //this.rowSelection = "multiple";
    //this.rowSelection = null;
    /*
    this.statusBar = {
        statusPanels: [
            { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
            { statusPanel: "agTotalRowCountComponent", align: "center" },
            { statusPanel: "agFilteredRowCountComponent" },
            { statusPanel: "agSelectedRowCountComponent" },
            { statusPanel: "agAggregationComponent" },
        ],
    };*/

    /*
    this.sideBar = {
        toolPanels: ["columns", "filters"],
    };*/

    //this.sideBar = null;
    //this.sideBar = {
        //toolPanels: ["columns"],
    //};

    /*
        this.autoGroupColumnDef = {
            flex: 1,
            minWidth: 280,
            field: "athlete"
        };*/

    //this.serverSideStoreType = "partial";
    //this.rowGroupPanelShow = "always";
    //this.rowSelection = 'single';

    this.configureGrid();

    this.excelStyles = [
            {
                id: "cell",
                alignment: {
                    horizontal: 'Left', vertical: 'Center'
                },
            }
        ];

  },

  watch: {
    '$i18n.locale': function (newVal, oldVal)
    {

      this.gridApi.refreshHeader();

    },

    configUrl: {
      handler(newValue, oldValue){

        console.log("cambio a : ", newValue)

        


      },

    }
  },


  methods: {

    filterNodes(conditionFn)
    {
      let filteredNodes = []

      this.gridApi.forEachNode(node => {

        if(conditionFn(node)){
          filteredNodes.push(node)
        }
      })

      return filteredNodes
    },


    getRowNodeById(id)
    {
      let rowNode = this.gridApi.getRowNode(id)

      return rowNode
    },


    updateRow(id, data)
    {

      let rowNode = this.gridApi.getRowNode(id)

      if(rowNode)
      {
        rowNode.setData(data);
        this.gridApi.flashCells({ flashDelay: 2000, fadeDelay: 500, rowNodes: [rowNode] });
      }

    },

    refreshData()
    {
    },

    sendAction(action_name, data, params)
    {
      this.$emit('actionReceived', action_name, data, params);
    },

    getActionColumnDef()
    {

      return {
        flex: 1,
        cellEditor: null,
        cellEditorParams: [],
        cellEditorPopup: false,
        cellRenderer: this.actionsCellRenderer,
        cellRendererParams: {
          actions: this.actions
        },
        editable: false,
        field: "",
        headerCheckboxSelection: false,
        headerName: this.$t("Acciones"),
        headerTooltip: "Tooltip",
        initialSort: null,
        menuTabs: [],
        pinned: "right",
        sortable: false,
        cellStyle: { border: 'none' }
      }


    },

    setDataServerSide(data)
    {
      const { card, columnDefs } = data;

      const { title, subtitle, tooltipTitle, tooltipContent, sidebarContent } = card;

      if (this.actions.length > 0)
      {
        columnDefs.push(this.getActionColumnDef())
      }


      this.columnDefs = columnDefs;


      this.options.title = title;
      this.options.subTitle = subtitle;
      this.options.tooltipTitle = tooltipTitle;
      this.options.tooltipContent = tooltipContent;

      this.options.sidebarContent = sidebarContent

      this.isLoading = false;

    },

    setDataClientSide(data)
    {
      const { card, columnDefs, rowData, pinnedTopRows } = data;

      const { title, subtitle, tooltipTitle, tooltipContent, sidebarContent } = card;

      if (this.actions.length > 0)
      {
        columnDefs.push(this.getActionColumnDef())
      }


      this.columnDefs = columnDefs;
      this.rowData = rowData;
      this.pinnedTopRowData = pinnedTopRows;


      this.options.title = title;
      this.options.subTitle = subtitle;
      this.options.tooltipTitle = tooltipTitle;
      this.options.tooltipContent = tooltipContent;

      this.options.sidebarContent = sidebarContent

      this.isLoading = false;

    },


    configureGrid()
    {
      this.isLoading = true;

      this.getConfig().then((response) =>
      {

        if (this.debug)
        {
          console.log("getConfig", { response });
        }

        if (this.rowModelType == "serverSide")
        {
          this.setDataServerSide(response.data)
        }
        else if (this.rowModelType == "clientSide")
        {
          this.setDataClientSide(response.data)
        }
        else
        {

        }


      });

    },

    refreshRows(route = [], purge = true)
    {

      this.gridApi.refreshServerSideStore({ route: route, purge: purge });

    },

    flashRow(id){

      var rowNodeById = null

      this.gridApi.forEachNode(rowNode => {

        if(rowNode.data.id == id){
          rowNodeById = rowNode
        }
      })

      if(rowNodeById)
      {
        this.gridApi.flashCells({ flashDelay: 2000, fadeDelay: 500, rowNodes: [rowNodeById] });

      }


    },

    onCellValueChanged(params)
    {

      if (this.debug)
      {
        console.log("onCellValueChanged");
        console.log(params);

      }

      this.$emit('cellValueChanged', this.gridApi, this.gridColumnApi, params);

      this.editData(params);
    },

    onFilterChanged(params)
    {

      this.$emit('filterChanged', this.gridApi, this.gridColumnApi, params);

    },

    getConfig()
    {
      return axios.request({
        method: "get",
        url: this.configUrl,
        headers: {
          Accept: "application/json",
        },
      });
    },

    editData(params)
    {
      return axios.request({
        method: "post",
        url: this.editUrl,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: params.data,
      });
    },

    getData(params)
    {
      return axios.request({
        method: "post",
        url: this.dataUrl,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: params.request,
      });
    },

    getRows(params)
    {

      if (this.debug)
      {
        console.log(
          "[Datasource] - rows requested by grid: ",
          JSON.parse(JSON.stringify(params.request))
        );

      }


      this.getData(params)
        .then((response) =>
        {

          if (this.debug)
          {
            console.log("getData", { response });
          }

          params.success({
            rowData: response.data.rowData,
            rowCount: response.data.rowCount,
          });

          this.$emit('getRowsLoaded', this.gridApi, this.gridColumnApi, params);



        })
        .catch(function (error)
        {
          // handle error
          console.log(error);
          params.fail();
        });
    },

    onSelectionChanged() 
    {
      console.log(this.gridApi.getSelectedNodes())

      let selectedRows = this.gridApi.getSelectedRows();

      this.$emit('selectionChanged', selectedRows);
    },

    onGridReady(params) 
    {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      if (this.rowModelType == "serverSide")
      {
        const createServerSideDatasource = () =>
        {
          return {
            getRows: this.getRows,
          };
        };

        const updateData = () =>
        {
          // create datasource with a reference to the fake server
          var datasource = createServerSideDatasource();
          // register the datasource with the grid
          params.api.setServerSideDatasource(datasource);
        };

        updateData();

      }


      this.$emit('gridReady', this.gridApi, this.gridColumnApi, params);


    },

    onBtExport() {
            this.gridApi.exportDataAsExcel();
        },
  },
};


window.optionsValueFormatter = function optionsValueFormatter(value, options, ifNull="")
{
  try
  {
    if (value === undefined)
    {
      return ifNull
    }
 
    return options[value];
  }
  catch (error)
  {
    return "" + value;
  }
};


window.numberValueFormatter = function numberValueFormatter(value, decimals, ifNull="")
{
  try
  {
    if (value === undefined)
    {
      return ifNull
    }

    return value.toFixed(decimals);
  }
  catch (error)
  {
    return "" + value;
  }
};

window.localDateFormatter = function localDateFormatter(value)
{
  try
  {
    var localDate = new Date(value);
    return localDate.toLocaleString();
  }
  catch (error)
  {
    return "" + value;
  }
};




</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "@core/scss/vue/pages/ui-feather.scss";

//@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>


<style>
div.ag-watermark {
  display: none !important;
}
</style>

